import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {find} from "lodash";
import Img from "components/image";
import Link from "components/link";
import DefaultLayout from "components/layout/default";
import {EXTERNAL_LINK_PROPS} from "constants/index";
import style from "styles/pages/index.module.css";
import layoutStyle from "styles/layout.module.css";
import clientLogo1 from "images/client-propertyguru.svg";
import clientLogo2 from "images/client-tiket.com.svg";
import clientLogo3 from "images/client-gojek.svg";
import clientLogo4 from "images/client-moneysmart.svg";
import clientLogo5 from "images/client-ninja-van.png";
import clientDetrack from "images/client-detrack.png";
import featureAppSecurity from "images/feature-app-security.png";
import featureCloudSecurity from "images/feature-cloud-security.png";
import featureGovernance from "images/feature-governance.png";
import featurePeopleSecurity from "images/feature-people-security.png";
import featureResilience from "images/feature-resilience.png";
import storyfierScreen from "images/storyfier-screen.png";
import radiusTopCapImage from "images/product-radius-top-cap.svg";
import radiusBottomCapImage from "images/product-radius-bottom-cap.svg";
import badgeAriba from "images/badge-ariba.jpg";

function IndexPage(props) {
  let data = useStaticQuery(QUERY);
  let pageContent = data.pageContent.childPagesJson;
  let backgroundImages = data.backgroundImages.edges.map(edge => edge.node);
  let mainTigerImageFluid = data.mainTigerImage.childImageSharp.fluid;
  let background1ImageSrc = find(backgroundImages, {relativePath: "background-home-1.png"}).childImageSharp.fixed.src;
  let background3ImageSrc = find(backgroundImages, {relativePath: "background-home-3.png"}).childImageSharp.fixed.src;

  function renderSection1() {
    return <section className="relative m-0 bg-cover bg-center"
      style={{backgroundImage: `url(${background1ImageSrc})`}}>
      <div className="container mx-auto px-4 md:px-8 z-10 relative pt-6 md:pt-0">
        <h1 className="font-bold text-4xl lg:text-6xl pt-24 md:pt-48 lg:pt-64 leading-tight max-w-xl whitespace-pre-wrap">
          {pageContent.section1.headerText}
        </h1>
        <Link to={pageContent.section1.ctaUrl} className="btn btn-orange mt-6 inline-block">
          {pageContent.section1.ctaText}
        </Link>
        <h2 className="font-bold text-base md:text-xl text-center leading-tight mt-16 lg:mt-32 ">
          {pageContent.section2.headerText}
        </h2>
        <div className="partner logo placeholder mx-auto mt-4 text-center">
          <img className={style.clientLogo} src={clientLogo1} alt="PropertyGuru"/>
          <img className={style.clientLogo} src={clientLogo2} alt="Tiket.com"
            style={{padding: "3px 0"}}/>
          <img className={style.clientLogo} src={clientLogo3} alt="Go Jek"
            style={{padding: "6px 0"}}/>
          <img className={style.clientLogo} src={clientLogo4} alt="MoneySmart"
            style={{padding: "8px 0"}}/>
          <img className={style.clientLogo} src={clientLogo5} alt="Ninja Van"
            style={{height: "32px"}}/>
        </div>
      </div>
      <Img className={style.mainImage} fluid={mainTigerImageFluid} alt="Tiger"/>
    </section>;
  }

  function renderTestimonialSection() {
    return <section className="mt-24 md:mt-48">
      <div className="container mx-auto px-4 md:px-8 z-10 relative">
        <div className="mx-auto text-center">
          <div className="max-w-3xl text-xl md:text-3xl font-serif italic mx-auto">
            <div className="text-6xl leading-none -mb-4">“</div>
            <div>
              With Horangi, we’ve been able to significantly improve our cyber security hygiene and adopt best practices through their training of our employees.
            </div>
            <div className="text-6xl leading-none mt-4">”</div>
          </div>
          <div className="uppercase text-sm" style={{color: "#CFCFCF"}}>
            Fanny See, COO & Co-Founder, Detrack
          </div>
          <div>
            <div className="inline-block mt-4">
              <img className="align-top" src={clientDetrack} alt="Detrack" width={160}/>
            </div>
          </div>
        </div>
      </div>
    </section>;
  }

  function renderSection2() {
    return <React.Fragment>
      <img className="w-full z-1 pointer-events-none mt-48"
        src={radiusTopCapImage} alt="Background"/>
      <section className={style.featureSection}>
        <div className={style.featureWhiteBgTop}/>
        <div className="container relative mx-auto px-4 md:px-8 z-10 relative text-black pt-40">
          <div className="relative z-10 ml-0 md:ml-20 xxl:-ml-10">
            <h3 className="font-bold text-4xl text-center lg:text-left lg:text-5xl leading-tight whitespace-pre-wrap w-full md:w-1/2 xxl:w-2/3 mt-20 md:mt-0">
              {pageContent.section3.subHeaderText}
            </h3>
            <div className="text-xl mt-6 text-center lg:text-left md:w-1/3 xxl:w-2/3">
              {pageContent.section3.headerText}
            </div>
          </div>
          <div className={style.featureScreen}>
            <img src={storyfierScreen} alt="Storyfier Screen" className="mt-20"/>
          </div>
          <div className={style.featureBoxContainer}>
            <div className={style.featureBox}>
              <h4 className="uppercase font-bold">App Security</h4>
              <div className="relative mt-4">
                <img src={featureAppSecurity} width={80} alt="App Security" className="inline-block"/>
                <div className={style.featureLinks}>
                  <Link to="/products/scanner/">Code Scanner</Link>
                  <Link to="/solutions/#crypto-security">Crypto Security</Link>
                </div>
              </div>
            </div>
            <div className={style.featureBox}>
              <h4 className="uppercase font-bold">Cloud Security</h4>
              <div className="relative mt-4">
                <img src={featureCloudSecurity} width={80} alt="Cloud Security" className="inline-block"/>
                <div className={style.featureLinks}>
                  <Link to="/products/warden/">Warden</Link>
                  <Link to="/solutions/#security-assessment">Vulnerability Assessment</Link>
                  <Link to="/solutions/#penetration-testing">Penetration Testing</Link>
                </div>
              </div>
            </div>
            <div className={style.featureBox}>
              <h4 className="uppercase font-bold">Resilience</h4>
              <div className="relative mt-4">
                <img src={featureResilience} width={80} alt="Resilience" className="inline-block"/>
                <div className={style.featureLinks}>
                  <Link to="/solutions/#incident-response">Incident Response</Link>
                </div>
              </div>
            </div>
            <div className={style.featureBox}>
              <h4 className="uppercase font-bold">People Security</h4>
              <div className="relative mt-4">
                <img src={featurePeopleSecurity} width={80} alt="People Security" className="inline-block"/>
                <div className={style.featureLinks}>
                  <Link to="/products/trainer/">Trainer</Link>
                </div>
              </div>
            </div>
            <div className={style.featureBox}>
              <h4 className="uppercase font-bold">Governance</h4>
              <div className="relative mt-4">
                <img src={featureGovernance} width={80} alt="Governance" className="inline-block"/>
                <div className={style.featureLinks}>
                  <Link to="/solutions/#security-assessment">Cyber Strategy Assessment</Link>
                  <Link to="/solutions/compliance">Compliance</Link>
                  <Link to="/solutions/data-privacy">Data Compliance</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-48 sm:mb-32 lg:mb-16 xl:mb-0">
            <a href="https://service.ariba.com/Discovery.aw/ad/profile?key=AN01054885788"
              className="inline-block"
              {...EXTERNAL_LINK_PROPS}>
              <img src={badgeAriba} alt="Ariba" className="inline-block"
                style={{height: "88px"}}/>
            </a>
          </div>
        </div>
      </section>
      <img className="w-full z-1 pointer-events-none -mt-48"
        src={radiusBottomCapImage} alt="Background"/>
    </React.Fragment>;
  }

  function renderSection3() {
    let {feature1Image, feature2Image, feature3Image} = pageContent.section4;
    if (pageContent.section4.feature1Image) {
      feature1Image = <Img fluid={feature1Image.childImageSharp.fluid}
        alt={pageContent.section4.feature1HeaderText}/>;
    }
    if (feature2Image) {
      feature2Image = <Img fluid={feature2Image.childImageSharp.fluid}
        alt={pageContent.section4.feature2HeaderText}/>;
    }
    if (feature3Image) {
      feature3Image = <Img fluid={feature3Image.childImageSharp.fluid}
        alt={pageContent.section4.feature3HeaderText}/>;
    }
    return <section className="relative min-h-screen flex items-center">
      <div className="container mx-auto px-4 md:px-8 z-10 relative py-20 lg:py-40">
        <h3 className="font-bold text-3xl md:text-4xl lg:text-6xl leading-tight whitespace-pre-wrap">
          {pageContent.section4.headerText}
          {/* <Link to={pageContent.section4.ctaUrl} className="ml-4 md:ml-6 text-orange inline-block text-sm">
            {pageContent.section4.ctaText}
          </Link> */}
        </h3>
        <div className="relative mt-8 md:mt-8 pl-0 lg:pl-8 text-left">
          <div className={style.newsItemImage}>
            {feature1Image}
          </div>
          <div className={`${style.newsItemContent} p-6 lg:p-8`} data-position="left">
            <h4 className="text-sm font-bold">
              {pageContent.section4.feature1HeaderText}
            </h4>
            <p className="mt-4 font-bold">
              {pageContent.section4.feature1DescriptionText}
            </p>
            <Link to={pageContent.section4.feature1Url}
              className="text-orange inline-block mt-4" {...EXTERNAL_LINK_PROPS}>
              Read More
            </Link>
          </div>
        </div>
        <div className="relative mt-8 md:mt-48 sm:ml-0 md:ml-48 lg:ml-0 pr-0 lg:pr-8 text-right">
          <div className={style.newsItemImage}>
            {feature2Image}
          </div>
          <div className={`${style.newsItemContent} p-6 lg:p-8`} data-position="right">
            <h4 className="text-sm font-bold">
              {pageContent.section4.feature2HeaderText}
            </h4>
            <p className="mt-4 font-bold">
              {pageContent.section4.feature2DescriptionText}
            </p>
            <Link to={pageContent.section4.feature2Url}
              className="text-orange inline-block mt-4" {...EXTERNAL_LINK_PROPS}>
              Read More
            </Link>
          </div>
        </div>
        <div className="relative mt-8 md:mt-48 pl-0 lg:pl-8 text-left">
          <div className={style.newsItemImage}>
            {feature3Image}
          </div>
          <div className={`${style.newsItemContent} p-6 lg:p-8`} data-position="left">
            <h4 className="text-sm font-bold">
              {pageContent.section4.feature3HeaderText}
            </h4>
            <p className="mt-4 font-bold">
              {pageContent.section4.feature3DescriptionText}
            </p>
            <Link to={pageContent.section4.feature3Url}
              className="text-orange inline-block mt-4" {...EXTERNAL_LINK_PROPS}>
              Read More
            </Link>
          </div>
        </div>
      </div>
    </section>;
  }

  function renderSection4() {
    return <section className={`${style.successStoriesBackground} relative min-h-screen flex items-center`}
      style={{backgroundImage: `url(${background3ImageSrc})`}}>
      <div className="container mx-auto px-4 md:px-8 z-10 relative">
        <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
          {pageContent.section5.headerText}
        </h3>
        <div className="text-center">
          <Link to={pageContent.section5.ctaUrl} className="btn btn-orange mt-6 inline-block">
            {pageContent.section5.ctaText}
          </Link>
        </div>
      </div>
    </section>;
  }

  return <DefaultLayout location={props.location}
    title={pageContent.title} description={pageContent.description}>
    <div className={layoutStyle.headerPlaceholder}/>
    {renderSection1()}
    {renderTestimonialSection()}
    {renderSection2()}
    {renderSection3()}
    {renderSection4()}
  </DefaultLayout>;
}

const QUERY = graphql`
  {
    pageContent: file(relativePath: {eq: "pages/index.json"}) {
      childPagesJson {
        title
        description
        section1 {
          headerText
          ctaText
          ctaUrl
        }
        section2 {
          headerText
        }
        section3 {
          headerText
          subHeaderText
          ctaText
          ctaUrl
        }
        section4 {
          headerText
          ctaText
          ctaUrl
          feature1HeaderText
          feature1DescriptionText
          feature1Url
          feature1Image {
            childImageSharp {
              fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          feature2HeaderText
          feature2DescriptionText
          feature2Url
          feature2Image {
            childImageSharp {
              fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
          feature3HeaderText
          feature3DescriptionText
          feature3Url
          feature3Image {
            childImageSharp {
              fluid(maxWidth: 1280, toFormat: JPG, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        section5 {
          headerText
          ctaText
          ctaUrl
        }
      }
    }
    mainTigerImage: file(relativePath: {eq: "main-tiger.png"}) {
      relativePath
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    backgroundImages: allFile(filter: {
      relativePath: {
        in: [
          "background-home-1.png",
          "background-home-2.png",
          "background-home-3.png"
        ]
      }
    }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fixed(width: 1920) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  }
`;

export default React.memo(IndexPage);
